import { jarallax } from 'jarallax'

export default class Getintouch {
	constructor() {
		this.container = document.querySelector('.getintouch')
	}

	init() {
		jarallax(document.querySelectorAll('.jarallax'), {
			speed: 0.2
		})
	}
}
