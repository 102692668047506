import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default class Reviews {
	constructor() {
		this.container = document.querySelector('.reviews')
	}

	init() {
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper('.swiper', {
			modules: [Navigation, Pagination],
			// Optional parameters
			loop: true,

			// If we need pagination
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpoints: {
				1024: {
					slidesPerView: 3,
					spaceBetween: 50
				}
			}
		})
		this.addEvents()
	}

	addEvents() {}
}
