import { jarallax } from 'jarallax'
import validateTarget from 'validate-target'

export default class Hero {
	constructor() {
		this.container = document.querySelector('.hero')
		this.topBarHeight = document.querySelector('.topBar').offsetHeight
		this.knowMore = this.container.querySelector('.knowMoreButton')
		this.order = this.container.querySelector('.orderButton')
		this.estimation = this.container.querySelector('.estimation')

		this.onClickOnContainer = this.onClickOnContainer.bind(this)
	}

	init() {
		jarallax(document.querySelectorAll('.jarallax'), {
			speed: 0.2
		})
		this.addEvents()
	}

	addEvents() {
		this.container.addEventListener('click', this.onClickOnContainer)
	}

	onClickOnContainer(event) {
		const target = event.target

		const knowMoreClick = validateTarget({
			target,
			selectorString: '.knowMoreButton',
			nodeName: 'button'
		})
		const orderClick = validateTarget({
			target,
			selectorString: '.orderButton',
			nodeName: 'button'
		})

		if (knowMoreClick) {
			window.scroll({
				top: document.querySelector('.discover').offsetTop - this.topBarHeight,
				behavior: 'smooth'
			})
		}
		if (orderClick) {
			window.scroll({
				top: document.querySelector('.order').offsetTop - this.topBarHeight,
				behavior: 'smooth'
			})
		}
	}
}
