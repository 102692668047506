export default class Discover {
	constructor() {
		this.container = document.querySelector('.discover')
		this.discoverButton = this.container.querySelector('.discover-button')
		this.topBarHeight = document.querySelector('.topBar').offsetHeight

		this.onClickOnButton = this.onClickOnButton.bind(this)
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		this.discoverButton.addEventListener('click', this.onClickOnButton)
	}

	onClickOnButton() {
		window.scroll({
			top: document.querySelector('.menu').offsetTop - this.topBarHeight,
			behavior: 'smooth'
		})
	}
}
